import Button from '@components/Button';
import Input from '@components/Input';
import useAuth from '@features/MyAccount/hooks/use-auth';
import { useTranslation } from 'next-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import { transformResponse } from '@utilities/api';
import { camelCase } from 'lodash';

import Link from '@components/Link';
import {
  EmailInput,
  PasswordInput,
  FormGroup,
  InputWrapper,
  PasswordWrapper,
  ForgotPassword,
  ForgotPasswordText,
  BecomeMember,
  BecomeMemberText,
  InvalidUser,
  InvalidUserText,
} from './LoginForm.module.css';

const LoginForm = ({
  className,
  onLoginSuccess,
  onClickBecomeMember,
  showBecomeMemberLink,
  defaultValues,
}) => {
  const { t } = useTranslation(['account', 'common']);
  const { login, isLoading, isError } = useAuth(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { handleSubmit, control } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const onSubmit = (data) =>
    login(data, onLoginSuccess).catch(({ data: err }) => {
      const error = transformResponse(err);
      const translationString = camelCase(error);
      setErrorMessage(translationString);
    });

  const forceLower = (strInput) => {
    // eslint-disable-next-line no-param-reassign
    strInput.value = strInput.value.toLowerCase();
  };

  return (
    <div className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isError && (
          <div className={InvalidUser}>
            <p className={InvalidUserText}>
              {t(errorMessage, { ns: 'common' })}
            </p>
          </div>
        )}
        <div className={FormGroup}>
          <div className={InputWrapper}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: `${t('invalidEmail', { ns: 'common' })}`,
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  type="email"
                  onKeyUp={forceLower(field)}
                  label="E-mail"
                  placeholder={t('yourEmail', { ns: 'common' })}
                  error={fieldState.error}
                  autoComplete="username"
                  className={EmailInput}
                />
              )}
            />
          </div>
          <div className={InputWrapper}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                // minLength: 8,
                message: `${t('minPasswordLength')}`,
              }}
              render={({ field, fieldState }) => (
                <div className={PasswordWrapper}>
                  <Input
                    {...field}
                    type="password"
                    label={t('password', { ns: 'common' })}
                    placeholder={t('yourPassword')}
                    // tooltip="Så vi ved hvem der logger ind"
                    error={fieldState.error}
                    className={PasswordInput}
                    autoComplete="current-password"
                  />
                </div>
              )}
            />
          </div>
          <div className={ForgotPassword}>
            <Link className={ForgotPasswordText} href="/login/forgot-password">
              {`${t('forgotPassword', { ns: 'common' })}?`}
            </Link>
          </div>
        </div>
        <Button
          appearance="primary"
          size="extra-large"
          fullWidth
          type="submit"
          isLoading={isLoading}
        >
          {t('login', { ns: 'common' })}
        </Button>
      </form>
      {showBecomeMemberLink && (
        <div className={BecomeMember}>
          <Link
            href="/checkout/"
            onClick={onClickBecomeMember}
            className={BecomeMemberText}
          >
            {t('becomeMember', { ns: 'common' })}
          </Link>
        </div>
      )}
    </div>
  );
};

LoginForm.defaultProps = {
  defaultValues: {},
  // eslint-disable-next-line no-unused-vars
  onClickBecomeMember: (e) => {},
  showBecomeMemberLink: true,
  // usually you don't want to do anything, as the
  // useAuth hook handles redirection
  onLoginSuccess: null,
};

export default LoginForm;
