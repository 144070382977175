import { checkoutApi } from '@features/Checkout/services/checkout';
import { setMembershipIds } from '@features/Checkout/store';
import { membershipApi } from '@features/Membership/services/memberships';
import { userApi } from '@shared/services/users';
import {
  attemptLoginAsUser,
  authActions,
  failedMasqueradeLogin,
  setMasqueradeUser,
} from './index';

export const logOut = () => async (dispatch) => {
  dispatch(userApi.util.resetApiState());
  dispatch(membershipApi.util.resetApiState());
  dispatch(checkoutApi.util.resetApiState());
  dispatch(setMembershipIds([]));
  localStorage.clear();
  // call action to actually lougout
  dispatch(authActions.logOut());
};

export const logOutCheckout = () => async (dispatch) => {
  dispatch(userApi.util.resetApiState());
  dispatch(membershipApi.util.resetApiState());
  dispatch(checkoutApi.util.resetApiState());
  localStorage.clear();
  // call action to actually lougout
  dispatch(authActions.logOut());
};

export const loginAsUser = (token) => async (dispatch) => {
  // update state for status
  dispatch(attemptLoginAsUser());

  // validate the token, and login on success
  return fetch(`${process.env.USER_API_ENDPOINT}/user/me`, {
    headers: {
      authorization: token,
    },
  }).then(async (response) => {
    if (response.ok) {
      dispatch(userApi.util.resetApiState());
      dispatch(membershipApi.util.resetApiState());
      dispatch(checkoutApi.util.resetApiState());
      dispatch(setMembershipIds([]));
      localStorage.clear();

      dispatch(setMasqueradeUser(token));
    } else {
      dispatch(failedMasqueradeLogin());
    }
  });
};
