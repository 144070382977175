/* eslint-disable no-param-reassign */
import React from 'react';
import Container from '@components/Container';
import Heading from '@components/Heading';
import Section from '@components/Section';
import { getLayout } from '@shared/layouts/DefaultLayout';

import LoginForm from '@features/Auth/components/LoginForm';
import defaultMyAccountServerSideProps from '@features/MyAccount/utilities/helpers/default-my-account-server-side-props';
import { useTranslation } from 'next-i18next';
import { Root, Lead } from './Login.module.css';

export async function getStaticProps(context) {
  return defaultMyAccountServerSideProps(context);
}
const Login = () => {
  const { t } = useTranslation(['account', 'common']);

  return (
    <Container className={Root}>
      <Section>
        <Heading size="large">{t('login', { ns: 'common' })}</Heading>
        <div className={Lead}>{t('loginToSee', { ns: 'common' })}</div>
        <LoginForm />
      </Section>
    </Container>
  );
};

Login.getLayout = getLayout;

export default Login;
